<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>

      <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="请选择店铺" style="width: 140px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>

      <el-select class="filter-item" v-model="listQuery.terminal_type" placeholder="终端类型" style="width: 140px" clearable>
        <el-option label="刷脸" :value="1"></el-option>
        <el-option label="碰一下" :value="2"></el-option>
      </el-select>

      <el-input class="filter-item" v-model="listQuery.terminal_id" placeholder="终端号" style="width: 220px;" clearable/>

      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop.shop_name }}
        </template>
      </el-table-column>

      <el-table-column label="终端类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.terminal_type === 1" type="primary">刷脸</el-tag>
          <el-tag v-else-if="scope.row.terminal_type === 2" type="warning">碰一下</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="终端号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.terminal_id }}
        </template>
      </el-table-column>

      <el-table-column label="备注" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200" align="center" fixed="right" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30,50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item label="店铺" prop="shop_id">
          <el-select v-model="form.shop_id" filterable>
            <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="终端类型" prop="terminal_type">
          <el-select v-model="form.terminal_type">
            <el-option label="刷脸" :value="1"/>
            <el-option label="碰一下" :value="2"/>
          </el-select>
        </el-form-item>

        <el-form-item label="终端号" prop="terminal_id">
          <el-input v-model="form.terminal_id"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" :rows="3" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          shop_id: "",
          terminal_type: "",
          terminal_id: "",
        },
        dialogFormVisible: false,
        dialogStatus: "",
        textMap: {
          update: "修改",
          create: "新增",
        },
        btnLoading: false,
        form:{
          id: "",
          shop_id: "",
          terminal_type: "",
          terminal_id: "",
          remark: "",
        },
        rules: {
          shop_id: [{ required: true, message: "店铺不能为空", trigger: "change" }],
          terminal_type: [{ required: true, message: "终端类型不能为空", trigger: "change" }],
          terminal_id: [{ required: true, message: "终端号不能为空", trigger: "change" }],
        },
        shops: [],
      };
    },
    created() {
      this.getList();
      this.getShopList();
    },
    computed: {
      ...mapGetters(["schools","school_id","user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
        this.getShopList();
      },
    },
    methods: {
      getList() {
        this.listLoading = true;
        request({
          url: "/api/backend/shopTerminalDevice/list",
          method: "get",
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
          this.listLoading = false;
        });
      },
      getShopList() {
        request({
          url: "/api/backend/shop/list",
          method: "get",
          params: {
            page: 1,
            limit: 1000000,
            school_id: this.school_id,
          }
        }).then(response => {
          this.shops = response.data.data;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      resetForm() {
        this.form = {
          id: "",
          shop_id: "",
          terminal_type: "",
          terminal_id: "",
          remark: "",
        }
        this.btnLoading = false
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = "create"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = "update"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      saveData() {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: "/api/backend/shopTerminalDevice/save",
              method: "post",
              data: this.form
            }).then(() => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).catch(() => {
              this.btnLoading = false
            })
          }
        })
      },
      handleDelete(row) {
        this.$confirm("确认继续该操作", "提示", {
          type: "warning"
        }).then(() => {
          request({
            url: "/api/backend/shopTerminalDevice/delete",
            method: "post",
            data: {
              id: row.id
            }
          }).then(()=>{
            this.getList();
            this.list.length <= 1 && this.listQuery.page > 1 ? this.listQuery.page-- : false;
            this.$message({
              type: "success",
              message: "操作成功"
            });
          })
        }).catch(() => {});
      },
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
