import { render, staticRenderFns } from "./shopTerminalDevice.vue?vue&type=template&id=a20a82bc&scoped=true&"
import script from "./shopTerminalDevice.vue?vue&type=script&lang=js&"
export * from "./shopTerminalDevice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a20a82bc",
  null
  
)

export default component.exports